<template>
  <div>
    <dashboard-page-title :showAddBtn="false" @addEvent="$router.push({name: 'addMerchant'})">المتاجر المحذوفة</dashboard-page-title>

    <main-table :baseURL="VUE_APP_MARKETPLACE_LINK" :fields="fields" list_url="admin/stores/deleted-stores" :reloadData="reloadTable"></main-table>
  </div>
</template>
<script>
/* eslint-disable */ 
import { core } from '@/config/pluginInit'
import marketPlaceServices from '../services/marketPlace'
import { VUE_APP_MARKETPLACE_LINK } from '@/config/constant'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      VUE_APP_MARKETPLACE_LINK,
      fields: [
        { label: 'صورة الشعار', key: 'logo', type: 'image', class: 'text-right' },
        { label: 'صورة الغلاف', key: 'cover', type: 'image', class: 'text-right' },
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'التاريخ', key: 'created_at', class: 'text-right' },
        {
          label: 'التحكم',
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              icon: 'las la-sync',
              color: 'success-light',
              text: 'restore merchant',
              actionName: 'restoreMerchent',
              actionParams: ['id'],
              showAlert: true
            },
            {
              icon: 'las la-eye',
              color: 'success-light',
              text: 'عرض',
              ifNavigate: true,
              routePath: 'show-merchant/:id'
            },
          ]
        }  
        
      ],
      reloadTable: false
    }
  },
  methods: {
    addMerchant () {
      console.log('hello')
    },
    async restoreMerchent (obj) {
       await marketPlaceServices.restoreMerchent(obj.id, { status :'completed'}).then(res => {
          console.log("hello restore Merchant", res)
          core.showSnackbar('success', 'تم الاسترجاع بنجاح')
          this.reloadTable = true
      })
    },
    created () {
    this.$root.$on('restoreMerchent', this.restoreMerchent)
  },
  beforeDestroy () {
    this.$root.$off('restoreMerchent')
  }
  },
  created () {
    this.$root.$on('restoreMerchent', this.restoreMerchent)
  },
  beforeDestroy () {
    this.$root.$off('restoreMerchent')
  }
}

</script>
